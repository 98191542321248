import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import axios from 'axios'
import 'element-plus/dist/index.css'
import '@/css/icon.scss'
import App from './App.vue'
import router from './router'
import directives from '@/directives'
import getIp from './hooks/getIp'
const app = createApp(App);

Object.keys(directives).forEach((funName:string)=>{
    app.directive(funName, {
        mounted(el, binding) {
            directives[funName as keyof typeof directives](el, binding)
        }
    })
})
app.config.globalProperties.$axios = axios
app.use(router).use(ElementPlus).mount('#app')
getIp()
import type { routerType } from "@/types/routerType";
import { ref } from "vue";

const introductionRouters = ref<routerType[]>([
    { path: "Introduction", type: "企业简介", name: "企业简介" },
    { path: "Introduction", type: "人力资源", name: "人力资源" },
    { path: "Introduction", type: "科技研发", name: "科技研发" },
    // { path: "Introduction", type: "业务能力", name: "业务能力" },
    // { path: "Introduction", type: "内部治理", name: "内部治理" },
    { path: "Introduction", type: "环境建设", name: "环境建设" },
    { path: "Introduction", type: "企业资质", name: "企业资质" },
    { path: "Introduction", type: "发展历程", name: "发展历程" },
]);
const businessRouters = ref<routerType[]>([
    { path: "Business", type: "检验检测", name: "检验检测" },
    { path: "Business", type: "信息审计", name: "信息审计" },
    { path: "Business", type: "工程咨询", name: "工程咨询" },
    { path: "Business", type: "绩效评估", name: "绩效评估" },
    { path: "Business", type: "技术培训", name: "技术培训" },
    // { path: "Business", type: "成功案例", name: "成功案例" },
]);
const newRouters = ref<routerType[]>([
    { path: "News", type: 10000201, name: "政策法规类" },
    { path: "News", type: 10000202, name: "标准指南类" },
    { path: "News", type: 10000203, name: "通知公告类" },
]);
const branchRouters = ref<routerType[]>([
    { path: "Branch", type: "西藏办公区域", name: "西藏办公区域" },
    { path: "Branch", type: "四川办公区域", name: "四川办公区域" },
    { path: "Branch", type: "北京办公区域", name: "北京办公区域" },
    { path: "Branch", type: "广东办公区域", name: "广东办公区域" },
    { path: "Branch", type: "深圳办公区域", name: "深圳办公区域" },
])
const platformRouters = ref<routerType[]>([
    { path: "Branch", type: "广东办公区域", name: "广东办公区域" },
    { path: "Branch", type: "深圳办公区域", name: "深圳办公区域" },
])
export {
    introductionRouters,
    businessRouters,
    newRouters,
    branchRouters
}
// let BASE_URL = 'https://114.116.86.62/gxcata/'
// let BASE_URL = 'https://www.gxcata.com/gxcata/'
const hostname = window.location.hostname
let BASE_URL = hostname.includes('.com') ? `https://${hostname}/officialWebsite/gxcata/` : `http://${hostname}/officialWebsite/gxcata/`
// let BASE_URL = 'http://192.168.1.170:8081/gxcata/'
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168.1')) {
    BASE_URL = 'https://www.gxcata.com/officialWebsite/gxcata/'

}

export default BASE_URL

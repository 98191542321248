import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import mitt from '@/mitt/mitt'

const isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? true : false;
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/Introduction',
    name: 'Introduction',
    component: () => import('@/views/Introduction.vue')
  },
  {
    path: '/Business',
    name: 'Business',
    component: () => import('@/views/Business.vue'),
  },
  {
    path: '/Case',
    name: 'Case',
    component: () => import('@/components/business/CaseDetail.vue'),
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('@/views/News.vue'),
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: () => import('@/components/news/detail.vue'),
  },
  {
    path: '/Branch',
    name: 'Branch',
    component: () => import('@/views/Branch.vue')
  },
  {
    path: '/Co',
    name: 'Co',
    component: () => import('@/views/Co.vue')
  },
  {
    path: '/Platform',
    name: 'Platform',
    component: () => import('@/views/Platform.vue')
  },
  {
    path: '/Assess',
    name: 'Assess',
    component: () => import('@/views/Assess.vue')
  },
  {
    path: '/Tool',
    component: () => import('@/views/Tool.vue')
  },
  {
    path: '/QN',
    name: 'QN',
    component: () => import('@/views/QN.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  // 如果是移动端，直接跳转到移动端项目
  if (isMobile && ~window.location.href.indexOf('mobile') === 0) {
    window.location.href = 'https://www.gxcata.com/mobile'
  }
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  mitt.emit('changeNavgation', to.name)
  next();
})
export default router
